<template>
  <div class="enterprise">
    <div class="enterprise_banner">
      <img
        src="https://qxqy-online.sdhckjvip.com/official/banner/banner_22x.png"
        alt=""
      />
    </div>
    <div class="enterprise_container" style="">
      <div class="box enterprise_container_content">
        <div ref="boxOneRef">为企业提供营销运营解决方案</div>
        <div
          class="enterprise_container_content_box"
          :class="{ view_to_bottom: boxOneShow }"
        >
          <div class="enterprise_container_content_box_left">
            <div
              v-for="(item, index) in tabList"
              :key="index"
              @click="choseTabList(index)"
              :class="{ active_tab: tabIndex === index }"
            >
              <img
                :src="tabIndex === index ? item.seleIcon : item.icon"
                alt=""
              />
              <div>{{ item.title }}</div>
              <img
                v-if="tabIndex === index"
                src="../../assets/enterprise/active_right.png"
                alt=""
              />
            </div>
          </div>
          <div class="enterprise_container_content_box_right">
            <div
              class="enterprise_container_content_box_right_tit"
              :class="inAnimation ? 'Anim' : ''"
              @animationend="inAnimation = false"
            >
              <div>
                {{
                  tabIndex != 0
                    ? tabList[tabIndex].content
                    : enterpriseList[enterpriseListIndex].content
                }}
              </div>
              <div>
                {{
                  tabIndex != 0
                    ? tabList[tabIndex].tips
                    : enterpriseList[enterpriseListIndex].tips
                }}
              </div>
            </div>
            <div
              class="enterprise_container_content_box_right_con"
              v-if="tabIndex === 0"
            >
              <div
                v-for="(item, index) in enterpriseList"
                :key="index"
                :class="{ active_list: enterpriseListIndex === index }"
                @mouseenter="choseEnterpriseList(index)"
              >
                <img
                  :src="
                    enterpriseListIndex === index ? item.seleIcon : item.icon
                  "
                  alt=""
                />
                <div>{{ item.title }}</div>
              </div>
            </div>
            <div
              v-else
              class="enterprise_container_content_box_right_con_to"
              :class="inAnimation ? 'Anim' : ''"
              @animationend="inAnimation = false"
            >
              <div
                v-for="(item, index) in tabList[tabIndex].children"
                :key="index"
              >
                <img :src="item.icon" alt="" />
                <div>{{ item.title }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="enterprise_tips">
      <div class="box enterprise_tips_content">
        <div ref="boxTwoRef">优质的服务，全方位的支持</div>
        <div
          class="enterprise_tips_list_box"
          :class="{ view_to_bottom: boxTwoShow }"
        >
          <div v-for="(item, index) in tipsList" :key="index">
            <div class="enterprise_tips_list">
              <img :src="item.icon" alt="" />
              <div>{{ item.title }}</div>
              <div>
                {{ item.content }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "enterprise",
  data() {
    return {
      tabList: [
        {
          id: 1,
          title: "数字化营销解决方案",
          icon: require("../../assets/enterprise/list_icon1.png"),
          seleIcon: require("../../assets/enterprise/list_icon1_sele.png"),
        },
        {
          id: 2,
          title: "数字化福利解决方案",
          icon: require("../../assets/enterprise/list_icon2.png"),
          seleIcon: require("../../assets/enterprise/list_icon2_sele.png"),
          content: "打造幸福职场，提升企业满意度",
          tips: "优化员工福利品质，降低企业采购成本，扩大员工福利选择自由度，提升员工对企业的满意度的解决方案",
          children: [
            {
              icon: require("../../assets/enterprise/content_icon1.png"),
              title: "福利策划顾问+供应链管理团队+技术研发专家+客户服务团队",
            },
            {
              icon: require("../../assets/enterprise/content_icon2.png"),
              title:
                "优化福利体系，节日、生日、主题活动、团建、生活+员工认同感、福利感知",
            },
            {
              icon: require("../../assets/enterprise/content_icon3.png"),
              title: "帮助员工提升已有的福利丰富度，福利体验升级",
            },
            {
              icon: require("../../assets/enterprise/content_icon4.png"),
              title: "帮助企业拓展业务多样化的福利功能，福利效能升级",
            },
          ],
        },
        {
          id: 3,
          title: "权益商品行业解决方案",
          icon: require("../../assets/enterprise/list_icon3.png"),
          seleIcon: require("../../assets/enterprise/list_icon3_sele.png"),
          content: "覆盖全行业商品，构建权益新生态",
          tips: "提供多样化的商品覆盖，保障权益卡券的畅销性，再利用营销玩法，实现价值的变现，从而构建权益的新生态",
          children: [
            {
              icon: require("../../assets/enterprise/content_icon5.png"),
              title:
                "涵盖美食饮品、影音娱乐、购物、机酒出行、生活服务、知识阅读、运动健康、充值缴费、实物商品等1000余款商品，品类齐全，价格实惠，货量充足",
            },
            {
              icon: require("../../assets/enterprise/content_icon6.png"),
              title:
                "支持直充、批采，适用于拉新留存、会员权益、私域运营、礼品采购、员工福利",
            },
          ],
        },
        {
          id: 4,
          title: "会员营销一体化解决方案",
          icon: require("../../assets/enterprise/list_icon4.png"),
          seleIcon: require("../../assets/enterprise/list_icon4_sele.png"),
          content: "搭建会员服务模式，实现营销数字化",
          tips: "利用会员营销策略，提升用户的服务体验感，实现更个性化的定制服务",
          children: [
            {
              icon: require("../../assets/enterprise/content_icon7.png"),
              title:
                "会员制度采取以价值为基础的营销运营理念，改变传统的统一模式，提供更个性化的服务",
            },
            {
              icon: require("../../assets/enterprise/content_icon8.png"),
              title:
                "为企业、商户设计权益、会员体系，实现权益卡券的“发行+分发+核销”一体化",
            },
          ],
        },
      ],
      tabIndex: 0,
      enterpriseList: [
        {
          id: 1,
          title: "资源最大化",
          icon: require("../../assets/enterprise/tip_icon1.png"),
          seleIcon: require("../../assets/enterprise/tip_icon1_sele.png"),
          content: "新零售品牌场景营销资源最大化",
          tips: "融合线上线下场景，打造全生态闭环，让用户充分享受到超值权益的同时实现价值最大化",
        },
        {
          id: 2,
          title: "提升转化率",
          icon: require("../../assets/enterprise/tip_icon2.png"),
          seleIcon: require("../../assets/enterprise/tip_icon2_sele.png"),
          content: "直击用户体验，提升用户转化率",
          tips: "通过小额成本获取用户专属权益，每次消费都为用户提供专属权益体验，降低消费门槛，缩短用户购买决策时间，提升非会员用户转化率",
        },
        {
          id: 3,
          title: "提高复购率",
          icon: require("../../assets/enterprise/tip_icon3.png"),
          seleIcon: require("../../assets/enterprise/tip_icon3_sele.png"),
          content: "增加优惠力度，提高商品复购率",
          tips: "用户购买卡券包后，刺激用户再次消费获取更大的优惠，提高用户到店次数",
        },
        {
          id: 4,
          title: "实现交叉推广",
          icon: require("../../assets/enterprise/tip_icon4.png"),
          seleIcon: require("../../assets/enterprise/tip_icon4_sele.png"),
          content: "利用权益卡券，实现业务的交叉推广",
          tips: "随着数字消费全面普及，权益卡券作为一种新型消费形态，为企业和消费者带来极大的便捷性。折扣券、红包可以用于二次“拉新”，实现业务的交叉推广，让用户尽快产生粘性，提升忠诚度",
        },
        {
          id: 5,
          title: "加速流量变现",
          icon: require("../../assets/enterprise/tip_icon5.png"),
          seleIcon: require("../../assets/enterprise/tip_icon5_sele.png"),
          content: "多平台流量引入，加速消费流量变现",
          tips: "电商、视频、社交等平台的巨大流量入口进行支付营销，锁定用户的消费行为将流量变现。",
        },
      ],
      enterpriseListIndex: 0,
      inAnimation: true,
      tipsList: [
        {
          id: 1,
          title: "全场景服务方案",
          icon: require("../../assets/enterprise/tab_icon1.png"),
          content:
            "适用范围覆盖金融、互联网、通信、生活服务等100+行业场景解决方案及策略服务，助力数字化转型与数字产业升级",
        },
        {
          id: 2,
          title: "全方位业务响应",
          icon: require("../../assets/enterprise/tab_icon2.png"),
          content:
            "提供7*24小时全天候专业人工客服，满足客户各种类型的业务诉求，保证最迅速的响应支持",
        },
        {
          id: 3,
          title: "专业化技术支持",
          icon: require("../../assets/enterprise/tab_icon3.png"),
          content:
            "提供数字化运营、营销、研发及赋能搭建服务，做到技术有支持，服务有温度，落地有闭环",
        },
        {
          id: 4,
          title: "全领域货源支持",
          icon: require("../../assets/enterprise/tab_icon4.png"),
          content:
            "提供生活全领域货源支持，保证合作便捷，安全稳定现货现发的高性价比业务服务",
        },
        {
          id: 5,
          title: "高质量资源团队",
          icon: require("../../assets/enterprise/tab_icon5.png"),
          content:
            "提供平台API快速搭建，及强大资源的商务团队引入更多优质品牌服务合作",
        },
        {
          id: 6,
          title: "全平台活动策划",
          icon: require("../../assets/enterprise/tab_icon6.png"),
          content:
            "整合行业优势资源，保障价格实惠，不定期推出爆款产品，享受大额优惠",
        },
      ],
      coverImgUrl:
        "https://qxqy-online.sdhckjvip.com/official/enterprise/marketing_bg@2x.png",
      currentScroll: 0,
      boxOneShow: false,
      boxTwoShow: false,
    };
  },
  watch: {
    enterpriseListIndex() {
      this.inAnimation = true;
    },
    tabIndex() {
      this.inAnimation = true;
    },
  },
  computed: {
    // bgStyle() {
    //   return {
    //     backgroundImage: "url(" + this.coverImgUrl + ")",
    //   };
    // },
  },
  mounted() {
    window.addEventListener("scroll", this.handalScroll, true);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handalScroll, true);
  },
  methods: {
    handalScroll() {
      this.currentScroll =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0;
      if (this.currentScroll * 2 >= this.$refs.boxOneRef.offsetTop) {
        this.boxOneShow = true;
      }
      if (this.currentScroll * 2 >= this.$refs.boxTwoRef.offsetTop) {
        this.boxTwoShow = true;
      }
    },
    choseEnterpriseList(index) {
      this.enterpriseListIndex = index;
    },
    choseTabList(index) {
      this.tabIndex = index;
      if (index === 0) {
        this.coverImgUrl =
          "https://qxqy-online.sdhckjvip.com/official/enterprise/marketing_bg@2x.png";
      }
      if (index === 1) {
        this.coverImgUrl =
          "https://qxqy-online.sdhckjvip.com/official/enterprise/welfare_bg@2x.png";
      }
      if (index === 2) {
        this.coverImgUrl =
          "https://qxqy-online.sdhckjvip.com/official/enterprise/business_bg@2x.png";
      }
      if (index === 3) {
        this.coverImgUrl =
          "https://qxqy-online.sdhckjvip.com/official/enterprise/member_bg@2x.png";
      }
    },
  },
};
</script>
<style scoped>
div {
  box-sizing: border-box;
}
.enterprise_banner {
  width: 100%;
  height: 700px;
}
.enterprise_banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.enterprise_container {
  width: 100%;
  height: 760px;
  padding-top: 90px;
  background-image: url('https://qxqy-online.sdhckjvip.com/official/banner/marketing_bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.enterprise_container_content > div:nth-child(1),
.enterprise_tips_content > div:nth-child(1) {
  text-align: center;
  font-size: 40px;
  color: #333;
  font-weight: 500;
}
.enterprise_container_content_box {
  display: flex;
  width: 100%;
  height: 392px;
  margin-top: 76px;
  transform: translateY(100px);
  opacity: 0;
}
.enterprise_container_content_box_left {
  width: 362px;
  background: #f1f4fb;
  border-radius: 16px 0 0 16px;
  padding: 24px 16px;
}
.enterprise_container_content_box_left > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 68px;
  border-radius: 14px;
  margin-top: 24px;
  padding: 0 16px;
}
.enterprise_container_content_box_left > div:nth-child(1) {
  margin-top: 0;
}
.enterprise_container_content_box_left > div > img:nth-child(1),
.enterprise_container_content_box_left > div > img:nth-child(3) {
  width: 26px;
  height: 26px;
}
.enterprise_container_content_box_left > div > div {
  flex: 1;
  margin-left: 12px;
  font-size: 20px;
  color: #292c33;
  font-weight: 500;
  cursor: pointer;
}
.active_tab {
  background-image: url("../../assets/enterprise/active_bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.active_tab > div {
  color: #fff !important;
}
.enterprise_container_content_box_right {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #f9fcfe;
  border-radius: 0 16px 16px 0;
  padding: 40px;
}
.enterprise_container_content_box_right_tit div:nth-child(1) {
  font-size: 32px;
  color: #292c33;
  font-weight: 500;
}
.enterprise_container_content_box_right_tit > div:nth-child(2) {
  font-size: 18px;
  color: #555659;
  margin-top: 16px;
  min-height: 60px;
}
.enterprise_container_content_box_right_con {
  flex: 1;
  display: flex;
  margin-top: 20px;
}
.enterprise_container_content_box_right_con > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 143px;
  height: 126px;
  border: 1px solid #e6eaf2;
  cursor: pointer;
}
.enterprise_container_content_box_right_con > div:nth-child(1) {
  border-radius: 8px 0 0 8px;
}
.enterprise_container_content_box_right_con > div:nth-child(5) {
  border-radius: 0 8px 8px 0;
}
.enterprise_container_content_box_right_con > div > img {
  width: 28px;
  height: 28px;
}
.enterprise_container_content_box_right_con > div > div {
  font-size: 16px;
  color: #292c33;
  margin-top: 12px;
}
.enterprise_container_content_box_right_con_to > div {
  display: flex;
  margin-top: 16px;
}
.enterprise_container_content_box_right_con_to > div:nth-child(1) {
  margin-top: 30px;
}
.enterprise_container_content_box_right_con_to > div > img {
  width: 28px;
  height: 28px;
}
.enterprise_container_content_box_right_con_to > div > div {
  flex: 1;
  margin-left: 12px;
  font-size: 20px;
  color: #69728c;
  font-weight: 500;
}
.active_list {
  background: linear-gradient(136deg, #3173f6 0%, #4ba6f8 100%);
  border: 1px solid #3173f6 !important;
}
.active_list div {
  color: #fff !important;
}

.enterprise_tips {
  width: 100%;
  /* height: 820px; */
  background: linear-gradient(180deg, #dee6fc 0%, #ffffff 100%, #f2f8ff 100%);
}

.enterprise_tips_content {
  padding: 126px 0 142px;
}

.enterprise_tips_list_box {
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
  transform: translateY(100px);
  opacity: 0;
}
.enterprise_tips_list_box > div {
  width: 33.33%;
}
.enterprise_tips_list_box > div:nth-child(3n) {
  display: flex;
  justify-content: flex-end;
}
.enterprise_tips_list_box > div:nth-child(3n-1) {
  display: flex;
  justify-content: center;
}
.enterprise_tips_list {
  width: 260px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 64px;
}

.enterprise_tips_list > img {
  width: 52px;
  height: 52px;
}

.enterprise_tips_list > div:nth-child(2) {
  font-size: 20px;
  color: #292c33;
  font-weight: 600;
  margin-top: 16px;
}

.enterprise_tips_list > div:nth-child(3) {
  font-size: 14px;
  color: #85888c;
  font-weight: 500;
  margin-top: 16px;
  text-align: center;
}

.Anim {
  animation: showMsg 300ms linear;
}

@keyframes showMsg {
  from {
    opacity: 0;
    transform: translateX(40px);
  }
  to {
    opacity: 1 !important;
  }
}

.view_to_bottom {
  transform: translateY(0) !important;
  opacity: 1 !important;
  transition: all 0.5s ease;
}
@media (max-width: 1200px) {
  
  .enterprise_container_content_box_right_con{
    transform:scale(1.2) ;
    transform-origin: left;
  }
  .enterprise_container_content_box_right_con_to{
    width: 80%;
    transform:scale(1.2) ;
    transform-origin: left;
  }
  .enterprise_tips_list{
    /* width: 520px; */
    width: 460px;
    
  }
  .enterprise_tips_list img{
      width: 80px;
      height: 80px;
    }
  .enterprise_tips_list > div:nth-child(2) {
      font-size: 40px;
     
    }

  .enterprise_tips_list > div:nth-child(3) {
    font-size: 28px;
    }
  .enterprise_container_content_box{
    opacity: 1;
    transform: translateY(0px);
  }
  .enterprise_tips_list_box{
    opacity: 1;
    transform: translateY(0px);
  }
}

</style>